.SearchPage{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    align-items: stretch;
}

.SearchPageSearch{
    margin-top: 20px;
}

.SearchPageResultsSection{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    margin-bottom: 40px;
}

.SearchPageResults{
    margin-right: 20px;
    min-width: 800px;
}

.NewsletterLabel .MuiFormControlLabel-label {
    color: #505A6B;
    font-size: 14px;
}

.SearchNoResults{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    margin-top: 20px;
}

.job_search{
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 600;
    color: #343639;
}

.SearchNoResultsSubtitle {
    /* color: #505A6B; */
    margin-top: 5px;
    margin-bottom: 5px;
}

.SearchNoResultsTitle{
    font-weight: 600;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.SearchNoResultsImg {
    filter: grayscale(70%)
}

.PostJobAd{
    width: 300px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #d8d6d6;
    color: #504D4A;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #f1eae7;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("https://cdn.archjobs.co.uk/file/Archjobs/backgrounds/work_with_us_bg.jpg"); */
}

.PostJobAdLink{
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration-line: underline;
    color: #3E3B37;
}

.PostJobAdHeader{
    color: #484E57;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.WeeklyAlertHeader{
    color: #484E57;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 30px;
}

.WeeklyUpdateSignUp{
    margin-top: 5px;
    height: 36px;
    display: flex;
    align-items: stretch;

}

.WeeklyAlert{
    width: 300px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #f0f0f0;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("https://cdn.archjobs.co.uk/file/Archjobs/backgrounds/job_alert_bg.jpg"); */

}

.JobAlertInput .MuiOutlinedInput-input {
    padding-top: 8px;
    padding-bottom: 8px;
    
}

.JobAlertInput {
    background: #fff;
}

.WeeklyUpdateMessage{
    min-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;

    justify-content: center;
}

.WeeklyAlertBanner {
    padding: 20px;
    margin-bottom: 10px;
    background-color: #FCFCFC;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px 2px rgb(207 217 232 / 62%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
}

.WeeklyAlertBanner .WeeklyAlertHeader{
    margin-top: 5px;
}

.SearchPageBanner {
    display: none;
}

.PostJobAdText{
    color: #484E57;
    font-weight: 300;
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.AdvertBox{
    width: 300px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #dac9c9;
    color: #504D4A;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #f1e5e5;
}

.AdvertBoxHeader{
    color: #484E57;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.AdvertBoxText{
    color: #484E57;
    font-weight: 300;
    font-size: 17px;
    text-align: center;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.AdvertBoxLink{
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    text-decoration-line: underline;
    color: #3E3B37;
    }

    .ArchAdemia{
        width: 300px;
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #dac9c9;
        color: #504D4A;
        border-radius: 4px;
        margin-top: 20px;
        background-color: #e9d5cf;
    }
    
    .ArchAdemiaHeader{
        color: #484E57;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .rchAdemiaText{
        color: #484E57;
        font-weight: 300;
        font-size: 17px;
        text-align: center;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .ArchAdemiaLink{
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        text-align: center;
        text-decoration-line: underline;
        color: #3E3B37;
        }

.JobAdButton{
    background-color: #4F8F41;
    min-height: 34px;
}

.JobAdButton:hover{
    background-color: #72a567;
}

.ResultsNumber{
    width: 100%;
    text-align: right;
    height: 20px;
    color: #484E57;
    font-size: 14px;
}

.ResultsGoBack{
    width: 100%;
    text-align: right;
    height: 20px;
    color: #484E57;
    font-size: 14px;
    text-decoration: underline;
    align-items: baseline;
    display: flex;
}

.BackArrow {
    font-size: 10px;
  }



.SearchResult{
    padding: 20px;
    margin-bottom: 10px;
    background-color: #FCFCFC;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px 2px rgba(207, 217, 232, 0.62);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: 218px;
    flex: 1 1 auto;
}

.SearchResultTop{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    min-width: 0
    /* overflow: hidden; */
}

.SearchResultSummary{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    flex: 1 1 auto;
    min-width: 0
}

.SearchResultImg{
    width: 130px;
    height: 130px;
    border: 1px solid #D5D5D5;
    margin-right: 10px;
}

.SearchResultTitle{
    font-weight: 500;
    font-size: 24px;
    color: #303640;
    margin-bottom: 5px;
}

.SearchResultCompany {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.SearchResultLocation{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.SearchResultSectors{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.SearchResultSalary{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.CompanyIcon{
    margin-right: 5px;
}

.LocationIcon{
    margin-right: 5px;
}

.SectorIcon{
    margin-right: 5px;
}

.SalaryIcon {
    margin-right: 5px;
}

.SearchResultBottom{
    color: #505A6B;
    font-weight: 300;
}

.SearchReadMore{
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    text-decoration-line: underline;
    margin-left: 5px;
    color: #3E4654;
    cursor: pointer;
}

/* .SearchResultsMain p{
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.SearchResultsMain ul{
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
} */

.SavedJobsTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #484E57;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
}

.SavedJobs{
    width: 300px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: #f4f7f8;
    border: 1px solid #dcdedf;
    border-radius: 4px;
    margin-top: 20px;
    padding-bottom: 10px;
}

.BigHeartContainer {
    background: #FFDDDD;
    border: 1px solid #FFACAC;
    color: #FC8383;
    font-size: 28px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SavedJobMessage{
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.SavedJobMessageSubtitle{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    color: #505A6B;
    font-size: 14px;
}

.SmallHeartSave{
    margin: 4px;
}

.SaveJobsElement{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding: 4px;

  }

  .SavedJobUnSave{
    font-size: 22px;
    cursor: pointer;
    color: #505A6B;
  }

  .SavedJobTitle{
    text-decoration-line: underline;
    color: #343639;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .SavedJobBusiness{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #484E57;
  }

  .SavedJobSecondary{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #484E57;
  }

  .SaveJobsDivider {
    border-top: 1px solid #D9D9D9;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }


  h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  h2 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  
  h3 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  ul {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .SimilarJobsSection{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .SimilarJobsSectionList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 15px;
  }

  .SimilarSearchList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;
  }

  .SimilarJobContainer{
    padding: 15px;
    margin-bottom: 10px;
    background-color: #FCFCFC;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px 2px rgba(207, 217, 232, 0.62);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
    justify-content: space-between;
    row-gap: 10px;
}

.SimilarJobTitle {
    color: #454E58;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.SimilarJobTop{
    display: flex;
    flex-direction: column;
}

.SimilarJobLink{
    color: #484E57;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
text-decoration-line: underline;
}


.SearchHeaderButtons{
    margin-top: -8px;
    height: 24px;
}

.SearchNoResultsImg{
    height: 200px;
}

.SimilarSearches{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;
}

.SimilarJobsSectionTitle {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #343639;
    margin-bottom: 5px;
 }

  @media (max-width: 1200px){
    .SearchPageResultsSection{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .SearchPageBanner {
        display: block;
    }

    .SearchPageSidebar{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;

        column-gap: 10px;
    }


    .SearchPageResults{
        width: 100%;
        min-width: auto;
        margin-right: 0px;
        max-width: 100%;
        align-items: stretch;
    }
  }

  @media (max-width: 600px){
    .SearchResult{
        padding: 10px;
        font-size: 90%;
    }

    .SimilarJobsSectionList{
        grid-template-columns: 1fr 1fr;
      }
    

    .SearchResultImg{
        width: 70px;
        height: 70px;
    }

    .SearchResultTags{
        margin-left: -90px;
    }

    .SearchHeaderButtons {
        margin-top: -2px;
        height: 16px;
       }

    .SearchHeaderButtons .SmallButton{
        font-size: 0px;
    }

    .SearchHeaderButtons .SmallButton .SmallButtonIcon{
        font-size: 14px;
        margin: 0;
    }
    
    .SearchHeaderButtons .SmallButton .SmallHeart{
        font-size: 18px;
        margin: 0;
    }

    .SearchResultTitle{
        font-size: 20px;
    }
    
    .SearchResultCompany{
        font-size: 16px;
    }
    .SearchResultLocation{
        font-size: 16px;
    }
    .SearchResultSectors{
        font-size: 16px;
    }

    .JobTag{
        font-size: 14px;
    }

    .Button.JobAdHeaderApplyButton{
        font-size: 14px;
        height: 30px;
        min-width: 100px;
    }

    .JobAdHeaderApply{
        height: 30px;
    }

    .JobAdHeader{
        flex-direction: column;
        align-items: center;
    }

    .SearchResultDateUploaded{
        font-size: 12px;
    }


    .ExpandArrow{
        font-size: 18px;
    }

    .SearchResult{
        max-width: 100%;
    }
  }

  .OptionTagType{
    font-size: 12px;
    color: #505A6B;
    font-weight: 300;
    margin-right: 5px;
  }

  .AutocompleteOptionSeparated{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex: 1 1 auto;
  }

  .MuiAutocomplete-option{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between !important;
    width: 100%;
    flex: 1 1 auto;
  }

  .SearchResultsMain img{
    max-width: 100%;
  }

  .SearchResultSummaryLine{
    flex: 1 1 auto;
  }

  @media (max-width: 520px){

    .RecruiterPage .SimilarJobsSectionList{
        grid-template-columns: 1fr ;
      }
    
}