
.SalaryGuidePage {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    width: 1100px;
    max-width: calc(100% - 20px);
    align-items: stretch;
    min-height: calc(100vh - 176px);
}

.SalaryGuideTitle{
    color: #343639;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 30px;
}

.SalaryGuideRoleSelection{
    display: flex;
    flex-direction: row;
    align-items: center;
    row-gap: 5px;
    column-gap: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
}

#experience_chart {
    min-width: 300px;
    min-height: 300px;
    flex: 1 1 auto;
}

.SalaryGuideContainer{
    padding: 10px 20px;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px 2px rgba(207, 217, 232, 0.62);
    border-radius: 4px;
    font-size: 16px;
    color: #484E57;
    margin-bottom: 40px;
}

.SalaryGuideHeader{
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.SalaryGuideRoleTitle{
    font-weight: 500;
    font-size: 32px;
    /* text-decoration-line: underline; */
}

.SalaryGuideLocation{
    font-weight: 300;
}

.SalaryGuideContentContainer{
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    max-width: 100%;
    min-width: 100%;
    column-gap: 5%;
}

.SalaryGuideLocations{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 450px;
    
}

.SalaryGuideMain{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
    row-gap: 25px;
}

.SalaryContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SalaryMain {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 14px 14px;
    width: 65%;
    text-align: center;
    margin-bottom: 10px;
    max-width: 100%;
}

.SalaryMainNumber {
    /* font-style: italic; */
    font-weight: 700;
    font-size: 34px;
    line-height: 44px;
    text-align: center;

    /* darker grey */

    color: #484E57;
}

.SalaryMainLabel {
    font-size: 22px;
    text-align: center;
    color: #3E4654;
}

.SalarySubLabel{
    font-style: italic;
    font-weight: 300;
    font-size: 22px;
    /* or 31px */

    text-align: center;

    color: #3E4654;
}

.SalaryRanges{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 18px;
}

.SalaryRangeLabel{
    font-weight: 300;
}

.SalaryRange {
    font-weight: 700;
    color: #484E57;
}

.SalaryGuideContentTitle{
    font-weight: 600;
    margin-bottom: 5px;
    color: #484E57;
    font-size: 18px;
}

.SalaryJobDescriptionContainer{
    font-weight: 300;
    min-height: 200px;
    margin-top: 10px;
}

.ExperienceChartContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #EDEDED;
    border-radius: 4px;
    padding: 5px 10px;
}

.SalaryChartTitle{
    text-align: center;
}

.grid .tick {
    stroke-width: 0.5px;
}

.grid .tick line{
    stroke: #aaaaaa;

}

.point-label {
    font-size: 14px;
  }

  .SalaryNote {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    color: #3E4654;
    margin-top: -15px
  }

  #salary_map{
    min-height: 500px;
    min-width: 400px;
  }

  .city_label{
    font-size: 12px;
    font-weight: 300;
  }

  .city_salary_label{
    font-size: 12px;
    font-weight: 600;
  }

  .circle-hover{
    cursor: pointer;
  }

  .SalaryTopLocation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 5px;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 300;
  }

.SalaryTopLocation:nth-child(odd) {
    background-color: rgba(0,0,0,0.08);
}

  .SalaryTopLocationSalary{
    font-weight: 600;
    color: #484E57;
  }

  .SalaryGuideMap{
    width: 100%;
  }

  .SalaryGuideMapTitle{
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 5px;
    color: #484E57;
  }

  .SalaryTopLocationWarning{
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    font-style: italic;
    margin-bottom: 10px;
  }

  .SalaryMainSampleSize{
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: -5px;
    /* text-align: right; */
  }

  .SalaryGuideLoading{
    min-height: 80vh;
  }

  .TopCities{
    max-width: 300px;
    width: 100%;
  }

  .ProgressionArrow{
    margin-top: 2px;
    margin-bottom: -2px;
    color: #343639;
  }

  .salaryProgression {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 6px;
    column-gap: 10px;
    border-radius: 4px;
  }

  .salaryProgression:nth-child(odd) {
    background-color: rgba(0,0,0,0.08);
}

  .salaryProgressionRow2{
    margin-left: 5px;
  }

  .salaryProgressionRow3{
    margin-left: 30px;
  }

  .SalaryProgressionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .CareerProgression{
    min-width: 400px;
    max-width: 100%;
  }

.SalaryGuideRoleSelectionDropdown{
    max-width: 90%;
    width: 300px;
}

.SalaryClickableRole{
    text-decoration: underline;
    cursor: pointer;
}

.SalaryGuideHeaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.SalaryGuideShare{
    display: flex;
    flex-direction: row;
    width: 90px;
    max-height: 30px;
}

.SalaryGuideLoading{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 15vh;
}

.SalarySimilarJobsSection {
    margin-top: 40px;
    margin-bottom: 20px;
}

  @media (max-width: 1100px) {
    .SalaryGuideContentContainer{
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 20px;
    }
  }

  @media (max-width: 500px) {
    .SalaryGuideContainer{
        padding: 5px 10px;
    }

    .CareerProgression{
        min-width: 90%;
    }

    .SalaryGuideLocations{
        min-width: 95%;
    }

    #salary_map{
        min-width: 100%;
    }

    .SalaryGuidePage{
        max-width: 100%;
    }
  }