.FindRecruiterTitle {
    margin-top: 6vh;
    color: #343639;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    max-width: 900px;
}

.FindRecruiterSubtitle {
    margin-top: 1vh;
    font-weight: 500;
    font-size: 22px; 
    text-align: center;
    
    /* grey text */
    
    color: #343639;
    
    text-align: center;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.FindRecruiterSubtitle2 {
    margin-top: 1vh;
    font-weight: 400;
    font-size: 18px; 
    text-align: center;
    width: 100%;
    /* grey text */
    
    color: #343639;
    
    text-align: center;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* wrap text */
    white-space: wrap;
}

.FindRecruiterSubtitle2 p {
    margin-top: 4px;
    margin-bottom: 10px;
}

.RecruiterList{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2vh;
    row-gap: 15px;
    max-width: 800px;
    min-height: 60vh;
}

.RecruiterCardHeaderRightActions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  
}

.RecruiterCardBannerImgContainer{
    padding-top: 20px;
}

.RecruiterCardBannerImg {
    width: 100%;

}

.RecruiterSearch{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    max-width: 800px;
}

.RecruiterSearchRegionLabel {
    font-size: 14px;
}

.RecruiterSearchJoinUs{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RecruiterSearch .MuiOutlinedInput-root .MuiSelect-multiple {
    min-height: 28px !important;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
}

.RecruiterSearch .MuiChip-label  {
    font-size: 12px;
}

.RecruiterSearch .PostJobInput {
    width: 300px;
}

.RecruiterSearch .MuiChip-root {
    height: 26px;
}

.RecruiterCardHeaderInfo {
    display: flex;

    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    row-gap: 2px;
    flex: 1;
}

.RecruiterCard{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 20px;

}

.RecruiterCardContainer {
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #FCFCFC;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px 2px rgba(207, 217, 232, 0.62);
    border-radius: 4px;
}

.SearchPageResults .RecruiterCardContainer{
    margin-bottom: 10px;
    max-width: 100%;
}

.SearchPageResults .RecruiterCard{
    padding-top: 16px;
}


.RecruiterCardHeaderBanner{
    background: #395E98;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;


    color: #FBFBFB;

}

.RecruiterCardHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D4D4D4;
}

.RecruiterCardImage {
    border: 1px solid #9f9f9f;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.RecruiterCardName {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */


    color: #303640;

}

.RecruiterCardHeaderRight {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
}

.RecruiterCardHeaderRightTop {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 5px;
}

.RecruiterCardHeaderRightBottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 5px;
}

.RecruiterCardHeaderButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    min-width: 130px;
}

.RecruiterCardCompany {
    font-weight: 400;
    font-size: 18px;
    color: #303640;
    margin-bottom: 5px;
}

.RecruiterCardShortDescription {
    color: #505a6b;
    font-weight: 300;
    margin-top: 10px;
    line-height: 21px;
 }

 .RecruiterCardInfo {
    padding: 5px;
    font-size: 16px;
    line-height: 21px;
 }

 .RecruiterCardHighlights {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 8px;
    row-gap: 4px;
    margin-top: 2px;
    margin-bottom: 5px;
    flex-wrap: wrap;
 }

 .RecruiterCardHighlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #505A6B;
    font-size: 16px;
    line-height: 18px;
    color: #505A6B;
    /* filter: drop-shadow(0 2px 2px rgba(87, 87, 87, 0.25)); */
    margin-top: 2px;
 }

 .RecuiterHighlightRowHeader{
    font-weight: 500;
    font-size: 16px;
    color: #303640;
    margin-top: 8px;
 }

 .RecruiterCardHighlightText{
    font-weight: 300;
    font-size: 14px;
    color: #505A6B;
 }

 .RecruiterCardHighlighthighlight_job_type{
    background-color: #FFF8BB;
    color: #7C7810;
    border: 1px solid #7C7810;
 }

 .RecruiterCardHighlighthighlight_location{
    background-color: #CDE8D8;
    color: #1A8C48;
    border: 1px solid #1A8C48;
 }

 .RecruiterCardHighlightExperience{
    background-color: #F9E5C8;
    color: #D38121;
    border: 1px solid #D38121;
 }

 .RecruiterHighlightGrid{
    display: grid;
    grid-template-columns: 130px 1fr;
    column-gap: 5px;
    row-gap: 5px;
    margin-top: 10px;
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 10px;
 }

 .RecruiterCardBanner {
    background: #395E98;
    color: #fff;
    padding: 10px;
    text-align: center;
    width: calc(100% + 20px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
 }

 .ModalRecruiterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .ModalRecruiterContainer .RecruiterCardName {
    font-size: 18px;
 }

 .ModalRecruiterContainer .RecruiterCardCompany {
    font-size: 14px;
 }

 .ModalRecruiterContainer .RecruiterCardHighlight  {
    font-size: 14px;
 }

 .ModalRecruiterContainer .RecruiterCardHighlightText  {
    font-size: 14px;
 }

 .ModalRecruiterContainer .RecruiterCardHeader{
    /* max-width: 400px; */
}

.AutocompleteOptionDisabled{
    color: #FC8383;
}

 .ModalRecruiterContainer  .RecruiterCardImage {
    height: 80px;
    width: 80px;
 }

 .RecruiterPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    max-width: 100%;
    width: 100%;
 }
 
 @media (max-width: 600px) {
    .RecruiterCardImage {
        width: 80px;
        height: 80px;
    }

    .RecruiterCardHeader {
        column-gap: 10px;
    }

    .RecruiterCardHeaderRight {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }

    .RecruiterCardHeaderRightActions{
        flex-direction: row;
        justify-content: space-between;
        column-gap: 10px;
        flex: 1 1 auto;
        margin-top: 5px;
    }

    .RecruiterHighlightGrid {
        grid-template-columns: 1fr;
    }

    .RecruiterCardHeaderButtons {
        flex-direction: column-reverse;
        justify-content: flex-end;
        min-width: 130px;
        row-gap: 10px;
        align-items: flex-end;
    }


 }

 @media (max-width: 500px) {
    .RecruiterCardHeaderRightTop {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        column-gap: 0px;
    }

    .RecruiterCardHeaderButtons {
        flex-direction: row;
        justify-content: flex-end;
        min-width: 130px;
        row-gap: 0px;
        column-gap: 4px;
        align-items: center;
    }

    .RecruiterCardHeaderButtons .SmallButton {
        font-size: 0px;
    }

    .RecruiterCardHeaderButtons .SmallButton .SmallButtonIcon {
        font-size: 14px;
        margin: 0;
    }

 }


 @media (max-width: 320px) {
    .RecruiterCardHeaderButtons {
        flex-direction: column-reverse;
        justify-content: flex-end;
        min-width: 130px;
        row-gap: 5px;
        align-items: flex-end;
    }

 }