.Button{
  position: relative;
  overflow: hidden;
  font-family: 'Roboto Flex';
  padding: 10px 14px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  background-color: #073631;
  color: #f6efe8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: 0px;
  transition: background 400ms;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.Recruitment .Button {
  background-color: #073631;
  padding: 12px 22px ;
  font-size: 24px;
  color: #F1F1F1;
}

.Recruitment .Button:hover{
  background-color: #2C625C;
}

.RecruitmentHomePageSection .Button:hover{
  background-color: #FF8F4F;
}

.RecruitmentHomePageSection .Button {
  background-color: #FDD85E;
  color: #292103;
  padding: 12px 22px ;
  font-size: 22px;
}

.YellowButton .Button:hover{
  background-color: #FF8F4F;
}

.YellowButton .Button {
  background-color: #FDD85E;
  color: #292103;
  padding: 12px 22px ;
  font-size: 22px;
}

.Button:disabled {
  background-color: #abc1e3 !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}


span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.Button:hover{
  background-color: #09554d;
}
