[data-custom-class='body'],[data-custom-class='body'] *{background: transparent !important;
}[data-custom-class='title'],[data-custom-class='title'] *{font-family: Arial !important;
font-size: 26px !important;
color: #000000 !important;
}[data-custom-class='subtitle'],[data-custom-class='subtitle'] *{font-family: Arial !important;
color: #595959 !important;
font-size: 14px !important;
}[data-custom-class='heading_1'],[data-custom-class='heading_1'] *{font-family: Arial !important;
font-size: 19px !important;
color: #000000 !important;
}[data-custom-class='heading_2'],[data-custom-class='heading_2'] *{font-family: Arial !important;
font-size: 17px !important;
color: #000000 !important;
}[data-custom-class='body_text'],[data-custom-class='body_text'] *{color: #595959 !important;
font-size: 14px !important;
font-family: Arial !important;

}[data-custom-class='link'],[data-custom-class='link'] *{color: #2D63BA !important;
font-size: 14px !important;
font-family: Arial !important;
word-break: break-word !important;
text-decoration: underline;
}
ul {
    list-style-type: circle;
  }
  ul > li > ul {
    list-style-type: square;
  }
  ul > li > ul > li > ul {
    list-style-type: circle;
  }
  ol li {
    font-family: Arial ;
  }


  .PrivacyPage{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .PrivacyPage .document-previewer-wrapper-a717db{
    max-width: 800px;
    margin: 10px;
  }

  .HomePage PrivacyPage{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    }

  .HomePage PrivacyPage .document-previewer-wrapper-a717db{
    max-width: 800px;
    margin: 10px;
    }
