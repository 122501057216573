.ScraperDashboard{
    width: 1100px;
    max-width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 10px;
}

.ScraperDashboardTitle{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
}

.ScraperDashboardLinkList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    align-items: center;
}

.ScraperUpdateButton{
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
}

.MuiTable-root {
    max-width: 100%;
}

.ScraperDashboard .MuiTableCell-root {
    max-width: 100px;
}


.ScraperDashboard .ChangesContainer {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 300px; /* adjust the value to your needs */
   
    text-overflow: ellipsis;
    -webkit-line-clamp: 8; /* Set the maximum number of lines */
    -webkit-box-orient: vertical;
    display: -webkit-box; /* Set the display type to a flexbox with a single column */
    white-space: normal; /* Allow text to wrap to the next line */
  }

  .MuiTable-root {
    background-color: #fff;
  }

  /* Make alternate rows slightly darker: */
  .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
    background-color: #f6f6f6;
  }

  .LinkSectionHeader{
    font-weight: 500;
    font-size: 16px;
  }

  .AdminAuth{
    min-height: 80vh;
  }

  .invalid-chip {
    background-color: rgba(255, 0, 0, 0.15) !important;
    color: white;
  }