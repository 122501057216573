.ArticleContainer{
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    width: 800px;
    max-width: calc(100% - 20px);
    font-size: 18px;
    line-height: 1.5;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;

}

.LivingWageArticle {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
}

.ArticlePage{
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: 60px;
    max-width: 100%;
}

.Recruitment .ArticlePage{
    margin-top: 50px;
}

.ArticleTitle{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 5px;
}

.ArticleSubtitle{
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px;
}

.ArticleHeaderImg{
    height: 500px;
    object-fit: cover;
    max-width: 100%;
}



.ArticleContainer p {
    margin-block-start: 8px;
    margin-block-end: 8px;
}

.ArticleImage{
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
    object-fit: cover;
}

.AuthorImg{
    border-radius: 50%;
    height: 100px;
}

.AuthorName{
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: row;

    /* don't allow word to go on next line */
    white-space: nowrap;
    
}

.AuthorComponent{
    display: flex;
    flex-direction: row; 
    align-items: center;
    margin: 10px;
    margin-top: 30px;
}

.ArticleListPage .ArticleContainer{
    justify-content: flex-start;
}

.AuthorProfile{
    margin-right: 20px;
}

.AuthorDescription{
    font-size: 16px;
    color: #484E57;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.AuthorDescription p {
    max-width: 800px;
}

.ArticleListTitle{
    font-weight: 600;
    text-align: center;
    font-size: 30px;
    margin: 10px;
    margin-top: 20px;
    max-width: 700px;
    color: #343639;
    
}

.ArticleList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.WhyWorkWithUsSection .ArticleList {
    max-width: 800px;
}

.SalaryGuideImg {
    max-width: 700px;
    border-radius: 10px;
    padding: 0px;
    width: 100%;
}

.JobAdImg {
    margin-top: -5%;
    margin-bottom: -5%;
}

.HiringHub .WhyWorkWithUsSectionSubtitle {
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 20px
}

.HiringHub .GreySection {
    padding-bottom: 20px;
}

.HiringHub .WhyWorkWithUsBannerTextContainer .WhyWorkWithUsSectionSubtitle {
    font-size: 20px;
    line-height: 23px;
}

.ArticlePreview {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #FCFCFC;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px 2px rgb(207 217 232 / 62%);
    border-radius: 4px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    row-gap: 6px;
}

.Recruitment .ArticlePreview {
    background-color: #fffdf5;
    border: 1px solid #d1cdbc;
    box-shadow: 0px 4px 4px 2px rgba(194, 193, 176, 0.62);
}

.ArticlePreviewTitle {
    font-weight: 500;
    font-size: 18px;
    color: #303640;
    margin-bottom: 5px;
    text-align: center;
    line-height: 24px;
}

.ArticlePreviewPublished {
    color: #353535;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 6px;
    text-align: center;
}

.ArticlePreviewDescription {
    color: #505A6B;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 20px;
}

.ArticlePreviewImage{
    border-radius: 4px;
    height: 160px;
    width: 220px;
    object-fit: cover;
}

.teams_blur{
    max-width: 350px;
}

.NewsletterEmailTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    max-width: 800px;
    padding-top: 40px;
    padding-bottom: 20px;
}

.inline {
    display: inline-block;
}

.ArticleCoaching{
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
}

.ArticleContainer table {
    width: 100%;
}

@media (max-width: 800px){
    .ArticleHeaderImg{
        height: 300px;
    }

    /* .ArticlePreview{
        flex-direction: column-reverse;
        align-items: center;
        width: 300px;
        max-width: 100%;
    }

    .ArticlePreviewTitle {
        text-align: center;
    } */

    .ArticleList{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 550px){
    .ArticleHeaderImg{
        height: 200px;
    }

    .ArticleContainer{
        font-size: 16px;
        padding: 10px;
    }

    .ArticleTitle{
        font-size: 22px;
    }

    .ArticleListTitle{
        font-size: 22px;
    }

    .AuthorDescription {
        font-size: 14px;
    }


    .ArticleSubtitle{
        font-size: 16px;
    }

    .AuthorComponent{
        flex-direction: column;
    }

    .ArticlePreview{
        padding: 10px;
    }

    .ArticleList{
        grid-template-columns: 1fr
    }
    
    .JobAlertInputWide {
        width: 100%;
    }

}