.OvertimeCalculatorPage {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
}

.OvertimeCalculatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
}

.OvertimeCalculatorTitle {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 5px;
}

.OvertimeCalculatorDescription {
    font-size: 18px;
    margin-bottom: 10px;
}

.OvertimeCalculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    max-width: 100%;
}

.OvertimeCalculatorButton{
    display: flex;
    flex-direction: column;
    column-gap: 4px;
    align-items: center;
}

.OvertimeCalculator .PostJobInput {
    width: 100%;
    flex: 1;
}

.OvertimeCalculatorInputSalary{
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
}

.Warning {
    color: #E96565;
  }

  .OvertimeCalculatorInputContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 10px;
  }

  .OvertimeCalculatorOutput {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    min-height: 340px;
  }

  .OvertimeCalculatorOutputTitle{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
  }

  .OvertimeCalculatorOutputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }

  .OvertimeCalculatorOutputText {
    font-size: 14px;
    font-weight: 300; 
    text-align: center;
  }

  .OvertimeCalculatorOutputSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 8px;
    padding: 10px 16px;
    background-color: #FCFCFC;
    border: 1px solid #D4D4D4;
    /* box-shadow: 0px 4px 4px 2px rgba(207, 217, 232, 0.62); */
    border-radius: 4px;
    font-size: 18px;
  }

  .OvertimeCalculatorOutputSectionDropdown {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 8px;
  }
  

  .OvertimeCalculatorOutputSectionTitle {
    font-weight: 600;
    font-size: 18px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 0px;
    position: relative;

  }

  .OvertimeCalculatorOutputSectionTitleTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    margin-top: 5px;
  }

  .OvertimeCalculatorOutputSectionTitle .ExpandButton {
    position: absolute;
    right: 4px;
    bottom: 0px;
    
  }

  .OvertimeCalculatorOutputSectionTitle .ExpandArrow {
    font-size: 16px;
  }

  .OvertimeCalculatorOutputSectionSubtitle {
    font-size: 14px;
    font-weight: 300; 
  }

  .OvertimeCalculatorOutputSectionRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 6px;
    color: #303640;    
    font-weight: 300; 
    font-size: 16px;
    
  }

  .OvertimeCalculatorOutputSectionRowValue {
    font-weight: 600;
    font-size: 18px;
  }

  .OvertimeCalculatorInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
  }
  
  .OvertimeCalculatorOutputLink {
    color: #303640;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }

  /* 500px max-width */
  @media (max-width: 500px) {
    .OvertimeCalculatorOutputContainer {
      grid-template-columns: 1fr;
    }
  }