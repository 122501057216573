.Navbar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    width: 100%;
    color: #353535;
    position: sticky;
    top: 0px;
    z-index: 10000;
    /* padding-left: 15px;
    padding-right: 15px; */
  }
  


  .NavBarMessage{
    background-color: #8b764e;
    font-size: 16px;
    width: 100%;
  }
  
  .NavbarContainer{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 1100px;
    max-width: calc(100% - 40px);
  }

  .Recruitment  .NavbarContainer{
    height: 50px;
  }
  
  .NavbarTitle{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-left: -5px;
    color: #343639;
  }

  .Recruitment .NavbarTitle{
    background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%);
    padding: 10px;
    border-radius: 10px;
    margin: 6px;
  }
  
  .NavbarLogoImg {
    height: 36px;
    min-width: 36px;
    margin: 5px;
    margin-right: 2px;
  }

    
  .Recruitment .NavbarLogoImg {
    height: 42px;
    min-width: 36px;
    margin: 5px;
    margin-right: 5px;
    border: 1px solid #FDD85E;
    border-radius: 50%;
  }
  
  .NavbarLinks{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    /* margin-right: -15px; */
  }
  
  .NavbarSearch{
    width: 360px;
    margin-right: -50px;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  
  .NavLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    padding: 5px 3px;
    cursor: pointer;
    margin: 0px 10px;
    color: #484E57;
    text-align: center;
    /* height: 100%;
    margin-top: -4px; */
  }
  
  .NavLink.active{
    border-bottom: 2px solid #073631;
  }
  
  .NavImg{
    height: 25px;
    min-width: 25px;
    margin-right: 5px;
    margin-left: -2px;
  }
  
  .NavDiscordImg{
    height: 20px;
    min-width: 26px;
    margin-left: -3px;
  }
  
  .Footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #353535;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
  }
  
  .FooterContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 1100px;
    padding: 10px 20px;
    max-width: 100%;
  }
  
  .CollapseMenuContainer{
    display: none;
    cursor: pointer;
    height: 100%;
    padding: 8px;
    margin-right: -5px;
    font-size: 30px;
  }

  .NavbarButtonContainer{
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  
  .ArticleLink {
    max-width: 200px;

  }


  
  .NavLinkDropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }


  .DropdownContainer {
    display: none;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    right: 0px;
    top: 44px;
    background-color: #fff;
    z-index: 10000;
    padding: 0px;
    /* border: 1px solid #D4D4D4; */
    box-shadow: 2px 4px 2px 2px rgba(151, 157, 165, 0.62);
    padding: 4px 4px;
    max-width: 90%;
    min-width: 120px;
  }

  .NavLinkDropdown:hover .DropdownContainer {
    display: flex;
  }


  .DropdownLink {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .DropdownContainer .DropdownLink {
    padding: 4px 8px;
    border-bottom: 1px solid #D4D4D4;
    border-top: 0px;
    align-items: center;
    justify-content: center;
  }

  .DropdownContainer .DropdownLink:last-child {
    border-bottom: none;
  }


  .DropdownContainer .DropdownLink.active {
    border-bottom: 1px solid #2D63BA;
  }

  .DropdownLink:hover {
    background-color: #F2F2F2;
    color: #353535
  }

  .Recruitment .Navbar {
    /* background: #073631; */
    background: rgba(7, 54, 49, 0.4);
    color: #F6EFE8;
    font-family: Inter;
    font-size: 20px;
    min-height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .Recruitment .Navbar.ContactPageNavBar {
    /* background: #073631; */
    background: rgba(7, 54, 49, 1);
  }

  .Recruitment .ArchjobsTitle1 {
    color: #F6EFE8;
    font-weight: 700;
  }

  .Recruitment .ArchjobsTitle2 {
    color: #FDD85E;
    font-weight: 700;
    margin-left: 2px;
  }

  .Recruitment .NavLink {
    color: #F6EFE8;
    /* text shadow */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    border-bottom-color: rgba(7, 54, 49, 0);
    border-top-color: rgba(7, 54, 49, 0);
  }
  
  .DropdownContainer .DropdownLink.active {
    border-bottom: 1px solid #FDD85E;
  }

    
  .Recruitment .NavLink.active{
    border-bottom: 2px solid #FDD85E;
  }
  
  @media(max-width: 900px){
    .NavbarLinks{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #fff;
      z-index: 10000;
      padding: 0px;
      border: 1px solid #D4D4D4;
      box-shadow: 0px 4px 4px 2px rgba(151, 157, 165, 0.62);
      border-radius: 4px;
      max-width: 90%;
    }
  
    .Recruitment .NavbarLinks{
      background-color: #073631;
      color: #F6EFE8;
      border: 0px solid #FDD85E;
      box-shadow: 0px 4px 4px 2px rgba(0,0,0, 0.2)
    }

  
    .NavbarLinks.hidden{
      display: none;
    }
  
    .CollapseMenuContainer{
      display: flex;
      color: #C1BBB5;
    }

    .DropdownContainer {
      display: flex;
      position: relative;
      top: 0px;
      right: 0px;
      text-align: left;
      align-items: stretch;
      box-shadow: none;
    }

    .DropdownContainer .DropdownLink {
      text-align: left;
      justify-content: flex-start;
    
      
    }

    .NavLinkDropdown {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: relative;
    }
  }

  .FooterContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    flex: 1 1 auto;
  }

  .FooterSubtext span {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 8px;
    border-right: 1px solid #353535;
  }

  .FooterSubtext{
    text-align: center;
  }

  .FooterTitle{
    font-size: 14px;
    font-weight: 500;
  }

  .FooterSearches{
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    line-height: 20px;
  }

  .FooterLogoImg{
    height: 40px;
    width: 40px;
  }

  .FooterLogo{
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #353535 !important;
  }

  .FooterLogoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .LinkedinLogo{
    font-size: 20px;
    padding-right: 3px;
    padding-left: 3px;
    color: #0A66C2;
  }
  
  .TwitterinLogo{
    font-size: 20px;
    padding-right: 3px;
    padding-left: 3px;
    color: #484E57;
  }

  .SocialMediaLinks{
    margin-top: 2px;
  }

  .Footer a {
    color: #484E57;
  }

  .ArticleLink {
    display: inline-flex;
    flex-direction: row;
  }

  .NavbarBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #f6efe8;
    color: #042421;
  }

  @media (max-width: 500px){
    .FooterContent {
      flex-wrap: wrap;
      row-gap: 5px;
      column-gap: 5px;
      flex-direction: column;
    }

    .FooterTitle{
      font-size: 16px;
      font-weight: 700;
    }

    .FooterSearches{
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 20px;
      max-width: 400px;
      row-gap: 3px;
    }

    .ArticleLink {
      max-width: 100%;
  
    }
    
  }